<template>
  <!-- SECTION modal-delete-osi -->
  <b-modal
    id="modal-delete-osi"
    :title="$t('reservation.osi.delete.title')"
    title-class="text-airline font-medium-4 font-weight-bolder"
    centered
    no-close-on-backdrop
    size="lg"
    @show="handleShowModal"
  >
    <!-- ANCHOR Srr List -->
    <b-form-group class="mb-0">
      <template #label>
        <span
          class="text-heading-5 mb-25"
        >
          {{ $t('reservation.osi.delete.osiList') }}
        </span>
      </template>
      <template v-slot="{ ariaDescribedby }">
        <b-form-radio
          v-for="ssr in ssrList"
          :key="ssr.ssrText"
          v-model="ssrSelected"
          :aria-describedby="ariaDescribedby"
          :value="ssr"
        >
          <span class="text-body-3 font-weight-bolder">{{ `${ssr.ssrType} ${ssr.ssrText}` }}</span>
        </b-form-radio>
      </template>
    </b-form-group>

    <template #modal-footer="{close}">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        size="md"
        variant="outline-secondary"
        class="center rounded-pill width-100"
        @click="close()"
      >
        {{ $t('reservation.close') }}
      </b-button>

      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="btn-gradient mt-lg-25  border-0"
        :disabled="!ssrSelected"
        pill
        @click="sendDeleteOSIHandle"
      >
        <span class="align-middle">{{ $t('reservation.osi.delete.title') }}</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BButton, BFormGroup, BFormRadio,
} from 'bootstrap-vue'
import {
  ref, toRefs,
} from '@vue/composition-api'

import { removeAccentsUpperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

import useReservationHandle from '@reservation/useReservationHandle'

export default {
  components: {
    BModal, BButton, BFormGroup, BFormRadio,
  },
  props: {
    reservationsData: {
      type: Object,
      default: () => {},
    },
    ssrList: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { osiFlight } = useReservationHandle()
    const bookingData = toRefs(props).reservationsData
    const ssrSelected = ref()

    function handleShowModal() {
      ssrSelected.value = null
    }

    function sendDeleteOSIHandle() {
      this.$bvModal.show('modal-api-loading')
      osiFlight({
        source: bookingData.value.source,
        agencyCode: bookingData.value.agency,
        paxCode: bookingData.value.paxContact.code,
        pnrNumber: bookingData.value.bookingCode,
        // osiUpdates: ssrSelected.value.map(pax => ({
        //   otherServiceInformation: {
        //     type: 'A',
        //     associatedPaxId: pax,
        //     airline: 'VN',
        //     freeText: osiContent.value,
        //   },
        //   operation: 'ADD',
        // })),
        osiUpdates: [{
          otherServiceInformation: {},
          operation: 'DELETE',
          id: ssrSelected.value.ssrText.split('|')[0],
        }],
        contact: bookingData.value.paxContact.id,
      })
        .finally(() => {
          this.$bvModal.hide('modal-api-loading')
          this.$bvModal.hide('modal-delete-osi')
        })
    }

    return {
      sendDeleteOSIHandle,
      removeAccentsUpperCaseFormatter,
      handleShowModal,
      ssrSelected,
    }
  },
}
</script>

<style lang="scss" scoped>
#modal-delete-osi ::v-deep {
  .custom-checkbox .custom-control-label,
  .custom-radio .custom-control-label {
    margin-top: 0;
  }
}
</style>
