var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-delete-osi",
      "title": _vm.$t('reservation.osi.delete.title'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    on: {
      "show": _vm.handleShowModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill width-100",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient mt-lg-25  border-0",
          attrs: {
            "disabled": !_vm.ssrSelected,
            "pill": ""
          },
          on: {
            "click": _vm.sendDeleteOSIHandle
          }
        }, [_c('span', {
          staticClass: "align-middle"
        }, [_vm._v(_vm._s(_vm.$t('reservation.osi.delete.title')))])])];
      }
    }])
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('span', {
          staticClass: "text-heading-5 mb-25"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.osi.delete.osiList')) + " ")])];
      },
      proxy: true
    }, {
      key: "default",
      fn: function fn(_ref2) {
        var ariaDescribedby = _ref2.ariaDescribedby;
        return _vm._l(_vm.ssrList, function (ssr) {
          return _c('b-form-radio', {
            key: ssr.ssrText,
            attrs: {
              "aria-describedby": ariaDescribedby,
              "value": ssr
            },
            model: {
              value: _vm.ssrSelected,
              callback: function callback($$v) {
                _vm.ssrSelected = $$v;
              },
              expression: "ssrSelected"
            }
          }, [_c('span', {
            staticClass: "text-body-3 font-weight-bolder"
          }, [_vm._v(_vm._s("".concat(ssr.ssrType, " ").concat(ssr.ssrText)))])]);
        });
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }