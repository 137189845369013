<template>
  <div>
    <AppCollapse
      type="border-info-2 card"
      class="mb-50"
    >
      <AppCollapseItem
        is-visible
        title
        class-header="card-header bg-blue-gradient p-0 m-50"
        class="border-info-2"
      >
        <template #header>
          <div class="d-flex-center justify-content-start gap-1">
            <b-img
              src="@icons/warning.svg"
              class="icon-card"
              width="50px"
            />
            <h6 class="text-airline font-medium-3 m-0 fw-700">
              {{ $t('reservation.notiFromSystem') }}
            </h6>
          </div>
        </template>
        <div class="text-dark font-weight-bolder">
          <template v-if="isEmpty(reservationsData.ssr)">
            <div class="fw-700 text-warning">
              {{ $t('reservation[\'No notification from the system\']') }}
            </div>
          </template>

          <template v-else>
            <div
              v-for="(item, index) of reservationsData.ssr"
              :key="index"
              class="mb-50"
            >
              <div v-if="item.ssrText">
                {{ item.ssrCode }} {{ item.ssrType }} {{ item.airline }} {{ item.status }} {{ item.paxId }} {{ item.ssrText }}
              </div>
            </div>
          </template>
        </div>
      </AppCollapseItem>
    </AppCollapse>

    <div
      v-if="!isHideFunctionButton && !hideFeature"
      class="w-100 d-flex-center justify-content-end mb-50"
    >
      <b-button
        v-if="reservationsData.source.includes('1S')"
        variant="danger"
        class="px-75 d-flex-center gap-1"
        size="sm"
        :disabled="!getAvailableSsrToDelete(reservationsData.ssr).length || isBookingOutSystem || !canIssueLimit"
        @click="openDeleteOsiModal"
      >
        <b-img
          src="@icons/clear.svg"
          width="16"
        />
        <span class="font-small-4">{{ $t('reservation.osi.delete.title') }}</span>
      </b-button>
    </div>
    <ModalDeleteOSI
      :reservations-data="reservationsData"
      :ssr-list="getAvailableSsrToDelete(reservationsData.ssr)"
    />
  </div>
</template>

<script>
import { BButton, BImg } from 'bootstrap-vue'
import isEmpty from 'lodash/isEmpty'

import useReservationHandle from '@reservation/useReservationHandle'

import ModalDeleteOSI from './ModalDeleteOSI.vue'

export default {
  components: {
    BButton,
    BImg,
    ModalDeleteOSI,

    AppCollapse: () => import('@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseItem: () => import('@core/components/app-collapse/AppCollapseItem.vue'),
  },
  props: {
    reservationsData: {
      type: Object,
      default: () => {},
    },
    isBookingOutSystem: {
      type: Boolean,
      default: false,
    },
    isHideFunctionButton: {
      type: Boolean,
      default: false,
    },
    canIssueLimit: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { hideFeature } = useReservationHandle()
    function openDeleteOsiModal() {
      this.$bvModal.show('modal-delete-osi')
    }

    function getAvailableSsrToDelete(ssrList) {
      return ssrList?.filter(ssrItem => ssrItem.ssrText.includes('VN VIP') || ssrItem.ssrText.includes('VN BKVN'))
    }
    return {
      isEmpty,
      openDeleteOsiModal,
      getAvailableSsrToDelete,
      hideFeature,
    }
  },
}
</script>
