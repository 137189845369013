var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('AppCollapse', {
    staticClass: "mb-50",
    attrs: {
      "type": "border-info-2 card"
    }
  }, [_c('AppCollapseItem', {
    staticClass: "border-info-2",
    attrs: {
      "is-visible": "",
      "title": "",
      "class-header": "card-header bg-blue-gradient p-0 m-50"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center justify-content-start gap-1"
        }, [_c('b-img', {
          staticClass: "icon-card",
          attrs: {
            "src": require("@icons/warning.svg"),
            "width": "50px"
          }
        }), _c('h6', {
          staticClass: "text-airline font-medium-3 m-0 fw-700"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.notiFromSystem')) + " ")])], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "text-dark font-weight-bolder"
  }, [_vm.isEmpty(_vm.reservationsData.ssr) ? [_c('div', {
    staticClass: "fw-700 text-warning"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'No notification from the system\']')) + " ")])] : _vm._l(_vm.reservationsData.ssr, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "mb-50"
    }, [item.ssrText ? _c('div', [_vm._v(" " + _vm._s(item.ssrCode) + " " + _vm._s(item.ssrType) + " " + _vm._s(item.airline) + " " + _vm._s(item.status) + " " + _vm._s(item.paxId) + " " + _vm._s(item.ssrText) + " ")]) : _vm._e()]);
  })], 2)])], 1), !_vm.isHideFunctionButton && !_vm.hideFeature ? _c('div', {
    staticClass: "w-100 d-flex-center justify-content-end mb-50"
  }, [_vm.reservationsData.source.includes('1S') ? _c('b-button', {
    staticClass: "px-75 d-flex-center gap-1",
    attrs: {
      "variant": "danger",
      "size": "sm",
      "disabled": !_vm.getAvailableSsrToDelete(_vm.reservationsData.ssr).length || _vm.isBookingOutSystem || !_vm.canIssueLimit
    },
    on: {
      "click": _vm.openDeleteOsiModal
    }
  }, [_c('b-img', {
    attrs: {
      "src": require("@icons/clear.svg"),
      "width": "16"
    }
  }), _c('span', {
    staticClass: "font-small-4"
  }, [_vm._v(_vm._s(_vm.$t('reservation.osi.delete.title')))])], 1) : _vm._e()], 1) : _vm._e(), _c('ModalDeleteOSI', {
    attrs: {
      "reservations-data": _vm.reservationsData,
      "ssr-list": _vm.getAvailableSsrToDelete(_vm.reservationsData.ssr)
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }